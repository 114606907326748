import { Button } from "@headlessui/react";
import ChevronFirst from "./icons/ChevronFirst";
import ChevronLast from "./icons/ChevronLast";
import ChevronLeft from "./icons/ChevronLeft";
import ChevronRight from "./icons/ChevronRight";
import classNames from "classnames";
import { range } from "lodash";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

interface PaginationButtonProps {
  disabled?: boolean;
  isSelected?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export default function Pagination({
  currentPage,
  totalPages,
  setCurrentPage,
}: PaginationProps) {
  return (
    <div className="flex flex-row">
      <PaginationButton
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(1)}
      >
        <ChevronFirst disabled={currentPage === 1} />
      </PaginationButton>
      <PaginationButton
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <ChevronLeft disabled={currentPage === 1} />
      </PaginationButton>
      {range(1, totalPages + 1).map((number) => (
        <PaginationButton
          key={number}
          isSelected={currentPage === number}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </PaginationButton>
      ))}
      <PaginationButton
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <ChevronRight disabled={currentPage === totalPages} />
      </PaginationButton>
      <PaginationButton
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage(totalPages)}
      >
        <ChevronLast disabled={currentPage === totalPages} />
      </PaginationButton>
    </div>
  );
}

function PaginationButton({
  disabled = false,
  isSelected = false,
  onClick,
  children,
}: PaginationButtonProps) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        "w-8 h-8 font-mono text-xs text-gray-900 justify-center items-center flex flex-row data-[hover]:bg-gray-200",
        { "font-bold": isSelected },
      )}
    >
      {children}
    </Button>
  );
}
