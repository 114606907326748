"use client";

import Link from "next/link";
import classNames from "classnames";
import InteractiveDot from "./icons/InteractiveCircle";
import { usePathname } from "next/navigation";
import { URL_PATHS } from "@/constants/navigation";
import { useAuth } from "@/context/authContext";

interface TabProps {
  children: React.ReactNode;
  currentPath: string;
  tabPath: string;
  onClick?: (e: React.MouseEvent) => void;
}

export default function Sidebar() {
  const pathname = usePathname();
  const { isLoggedIn, showAccessDeniedModal } = useAuth();

  const handleTransactionsClick = (e: React.MouseEvent) => {
    if (!isLoggedIn) {
      e.preventDefault();
      showAccessDeniedModal();
    }
  };

  return (
    <nav className="fixed w-64 h-full border-r border-gray-400 z-30 mt-28 py-5 pl-10 pr-5">
      <Tab currentPath={pathname} tabPath={URL_PATHS.REPORT}>
        Report
      </Tab>

      <Tab
        currentPath={pathname}
        tabPath={URL_PATHS.TRANSACTIONS}
        onClick={handleTransactionsClick}
      >
        Transactions
      </Tab>
    </nav>
  );
}

function Tab({ currentPath, tabPath, children, onClick }: TabProps) {
  const active = currentPath === tabPath;

  return (
    <Link href={tabPath} onClick={onClick}>
      <div className="py-5 flex flex-row items-center gap-2 group">
        <InteractiveDot active={active} />
        <span
          className={classNames(
            "font-mono font-bold text-xl",
            active
              ? "text-gray-900"
              : "text-gray-600 group-hover:text-gray-900",
          )}
        >
          {children}
        </span>
      </div>
    </Link>
  );
}
