import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { http } from "wagmi";
import { mainnet, anvil, sepolia } from "wagmi/chains";

const env = process.env.NEXT_PUBLIC_CHAIN_ENV || "production";
const rpcUrl = process.env.NEXT_PUBLIC_RPC_URL;
const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_ID!;

if (!projectId) {
  throw "NEXT_PUBLIC_WALLETCONNECT_ID not set";
}

if (!rpcUrl) {
  throw "NEXT_PUBLIC_RPC_URL not set";
}

const common = {
  appName: "Karpatkey",
  projectId,
  ssr: true,
};

function localDevConfig() {
  return getDefaultConfig({
    chains: [anvil],
    transports: { [anvil.id]: http(rpcUrl) },
    ...common,
  });
}

function stagingConfig() {
  return getDefaultConfig({
    chains: [sepolia],
    transports: { [sepolia.id]: http(rpcUrl) },
    ...common,
  });
}

function prodConfig() {
  return getDefaultConfig({
    chains: [mainnet],
    transports: { [mainnet.id]: http(rpcUrl) },
    ...common,
  });
}

export const config =
  env === "production"
    ? prodConfig()
    : env === "staging"
      ? stagingConfig()
      : localDevConfig();
