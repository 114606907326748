"use client";
import {
  Button,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import classNames from "classnames";
import Close from "./icons/Close";

interface ModalProps {
  title?: string;
  isTitleCentered?: boolean;
  isOpen: boolean;
  showBackdrop?: boolean;
  hasCloseButton?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export default function Modal({
  title,
  isTitleCentered = false,
  isOpen,
  showBackdrop = true,
  hasCloseButton = false,
  onClose,
  children,
}: ModalProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {showBackdrop && (
        <DialogBackdrop className="bg-opacity-60 fixed inset-0 bg-gray-900 backdrop-filter backdrop-blur-sm" />
      )}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="w-[472px] rounded-2xl bg-gray-100 ">
          {hasCloseButton && (
            <div className="flex justify-end py-2 px-3">
              <Button onClick={onClose}>
                <Close />
              </Button>
            </div>
          )}

          <div className={classNames("py-10", { "pt-5": hasCloseButton })}>
            {title && (
              <DialogTitle
                className={classNames(
                  "font-mono text-3xl px-10 font-semibold pb-10 text-gray-900",
                  {
                    "text-center": isTitleCentered,
                  },
                )}
              >
                {title}
              </DialogTitle>
            )}
            {children}
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
