"use client";

import { Toaster, ToastBar } from "react-hot-toast";
import Checkmark from "./icons/Checkmark";
import Warning from "./icons/Warning";

export default function Toast() {
  return (
    <Toaster
      toastOptions={{
        duration: 4000,
        style: {
          backgroundColor: "#DEDEDE",
          borderRadius: 4,
          padding: "8px 20px",
        },
        success: {
          icon: <Checkmark />,
        },
        error: {
          icon: <Warning />,
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t} style={{ maxWidth: 500 }}>
          {({ icon, message }) => (
            <div className="gap-2 flex-row flex items-center">
              {icon}
              <span className="font-sans text-base text-gray-900">
                {message}
              </span>
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
