"use client";

import Button from "@/components/Button";
import ConnectWalletButton from "@/components/ConnectWalletButton";
import { URL_PATHS } from "@/constants/navigation";
import { useAuth } from "@/context/authContext";
import Link from "next/link";
import { useRouter } from "next/navigation";

export default function TopBar() {
  const { isLoggedIn, showAccessDeniedModal } = useAuth();

  const router = useRouter();
  return (
    <nav className="w-full bg-gray-100 fixed px-10 h-28 flex flex-row justify-between items-center border-b border-gray-400 gap-6 z-30">
      <Link href="/" className="font-mono text-2xl font-semibold">
        karpatkey
      </Link>
      <div className="gap-10 flex">
        <div className="gap-4 flex">
          <Button
            variant="primary"
            onClick={() => {
              isLoggedIn
                ? router.push(URL_PATHS.SUBSCRIBE)
                : showAccessDeniedModal();
            }}
          >
            Subscribe
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              isLoggedIn
                ? router.push(URL_PATHS.REDEEM)
                : showAccessDeniedModal();
            }}
          >
            Redeem
          </Button>
        </div>

        <ConnectWalletButton />
      </div>
    </nav>
  );
}
