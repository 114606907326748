"use client";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import Button from "./Button";
import { getAddress } from "viem";
import Logout from "./icons/Logout";
import { shortenAddress } from "@/utils/addresses";

export default function ConnectWalletButton() {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              className: "opacity-0 pointer-events-none select-none",
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    variant="primary"
                    className="w-48"
                    onClick={openConnectModal}
                  >
                    Connect
                  </Button>
                );
              }

              if (chain.unsupported) {
                return (
                  <Button
                    className="w-48"
                    variant="primary"
                    onClick={openChainModal}
                  >
                    Switch Network
                  </Button>
                );
              }
              const address = getAddress(account.address);
              return (
                <Button
                  icon={<Logout />}
                  variant="secondary"
                  className="w-48"
                  onClick={openAccountModal}
                >
                  {shortenAddress(address)}
                </Button>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
